<template>
<v-container>
    <div>
        <navbar title="مرسوله"/>
        <div class="pt-15">
            <ordersCard class="mt-1" :cargos="cargos" />
            <card />
        </div>
    </div>
</v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import ordersCard from './components/cards/orders_card'
import card from './components/cards/orderProduct'
import { viewCargo } from './models/factors'
import { mapState } from 'vuex'
import { factorviweDetail } from './models/factors'
export default {
    props: ['cargoID'],
  components: {
      ordersCard,
      navbar,
      card
  },
  created () {
      viewCargo(this.$props.cargoID)
  },
  computed: {
      ...mapState({
        cargos: state => state.factors.cargos
      }),
      factorID () {
        return this.$props.factoryID
      }
    }
}
</script>