<template>
    <div>
      <div>
        <v-card class="card mb-2 flat-card pa-2" color="surface">
          <v-row class="pa-2 mx-1">
          <!-- <div>
            <span class="medium-font">تعدادکالا:</span>
            <span class="medium-font mr-1" v-text="status.total_items"></span>
          </div> -->
          <div>
            <span class="medium-font onSurface--text">زمان تحویل</span>
            <div class="d-flex">
              <div>
                <span class="medium-font mr-1 onSurface--text" v-text="status.delivery_date"></span>
              </div>
              <span class="medium-font mr-1 d-flex align-center onSurface--text">-</span>
              <div>
                <span class="medium-font mr-1 onSurface--text" v-text="status.delivery_shift"></span>
              </div>
            </div>
          </div>
          <div class="mr-auto ml-1">
            <span class="medium-font onSurface--text">وضعیت</span>
            <div>
              <span class=" primaryText--text medium-font" v-show="status.status == 6">تحویل داده شده</span>
              <span class=" success--text medium-font" v-show="status.status == 5">ارسال شده</span>
              <span class=" info--text medium-font" v-show="status.status == 4">درحال بسته بندی</span>
              <span class=" warning--text medium-font" v-show="status.status == 3">درحال آماده سازی</span>
              <span class=" info--text medium-font" v-show="status.status == 2">تایید شده</span>
              <span class=" error--text medium-font" v-show="status.status == 0">پرداخت نشده</span>
            </div>
          </div>
          </v-row>
      </v-card>
      </div>  
    </div>
</template>
<script>
import router from '@/router'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      status: state => state.factors.cargo_status,
      address: state => state.factors.factor_address_info,
    })
  }
}
</script>